import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


export default function Footer() {
  const quickLinks = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/about",
      title: "About Dr. SSK",
    },
    {
      url: "/services",
      title: "Services",
    },
    {
      url: "https://aakruthihealthcare.com/blog/",
      title: "Blog",
    },
    {
      url: "/contact",
      title: "Contact",
    },
    {
      url: "/bookappointment",
      title: "Book An Appointment",
    },
  ];

  const services = [
    {
      url: "/services/skincare",
      title: "Skin Care",
    },
    {
      url: "/services/haircare",
      title: "Hair Care",
    },
    {
      url: "/services/aesthetic",
      title: "Aesthetics Services",
    },
    {
      url: "/services/ayurveda",
      title: "Aakruthi's Ayurveda",
    },
  ];
  return (
    <div className="footer">
      <div className="container pt-5 pb-4">
        <div className="row">
          <div className="col-md-5">
            <img src={Logo} alt="LOGO" width={"140px"} height={"50px"} />
            <p className="pt-3">
              Aakruthi is an Ayurvedic cosmetology clinic founded by Dr. SSK, a
              dedicated practitioner with extensive experience in harnessing the
              power of Ayurveda for natural beauty care. We believe that true
              beauty lies in a holistic approach that nourishes both the inner
              and outer self.
            </p>
            <p className="icons pt-2">
              <b>Follow Us</b>
              <br />
              <Link
                to="https://www.facebook.com/profile.php?id=61559299757306"
                target="_blank"
              >
                <FaFacebook />
              </Link>{" "}
              <Link
                to="https://www.instagram.com/aakruthi_healthcare/"
                target="_blank"
              >
                <RiInstagramFill />
              </Link>
              <Link to="https://x.com/Aakruthi_Health" target="_blank">
                <FaXTwitter />
              </Link>
            </p>
          </div>
          <div className="col-md-2">
            <h6>Quick Links</h6>
            <ul className="list-unstyled">
              {quickLinks.map((item) => {
                return (
                  <li key={item.url}>
                    <Link to={item.url}>
                      <MdKeyboardArrowRight /> {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-md-2">
            <h6>Aakruthi’s Services</h6>
            <ul className="list-unstyled">
              {services.map((item) => {
                return (
                  <li key={item.url}>
                    <Link to={item.url}>
                      <MdKeyboardArrowRight /> {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-md-3">
            <h6>Reach Us</h6>
            <ul className="list-unstyled reach">
              <li>
                <Link to="https://maps.app.goo.gl/cfqU3MSQotwUhUZC8">
                  <FaMapMarkerAlt />{" "}
                  <span>
                    1st Floor, 2nd Cross, Veer Savarkar Road, Near Vasavi Mahal,
                    Chitradurga - 577501
                  </span>
                </Link>
              </li>
              <li>
                <Link to="tel:919900311141">
                  <MdCall /> <span>+91 99003 11141</span>
                </Link>
                <Link to="tel:919482476234">
                  <MdCall /> <span>+91 94824 76234</span>
                </Link>
              </li>
              <li>
                <Link to="mailto:Dr.Sushmitha@aakruthihealthcare.com">
                  <FaEnvelope />{" "}
                  <span>Dr.Sushmitha@aakruthihealthcare.com</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container pt-2 pb-2">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <p>
                Copyright © {new Date().getFullYear()}{" "}
                <Link to="/">
                  <b>Aakruthi Healthcare</b>
                </Link>{" "}
                . All Rights Reserved.
              </p>
            </div>
            <div className="col-md-4">
              <p className="privacy">
                <Link to="/termsconditions">Terms & Conditions</Link>{" "}
                <Link to="/privacypolicy">Privacy Policy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
