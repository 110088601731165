import React from "react";
import BreadCrumb from "../components/BreadCrumb";

export default function BookAnAppointment() {
  return (
    <>
      <BreadCrumb
        title="Book an Appointment at Aakruthi Healthcare"
        tagline="Empowering you to embrace your inner and outer radiance"
      />

      <div className="container pt-5 pb-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            {/* <h4>Fill the form below to book your appointment today!</h4>
            <small>
              Expect a call back from our team within 24 hours from the time of
              booking to discuss the day and time of the service.
            </small> */}
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdIgQOEtEm0hIAssU7sC7gqdULc9A8wxfNG5QhFJnuEcqMObQ/viewform?embedded=true"
              width="100%"
              height="700"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="Appointment Booking Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
}
