import React from "react";

export default function BreadCrumb(props) {
    return <>
        <div className="breadcrumb">
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-md-12">
                        <h3>{props.title}</h3>
                        <small><i>{props.tagline}</i></small>
                    </div>
                </div>
            </div>
        </div>
    </>
}
