import React from 'react'
import ButtonRound from './ButtonRound';

export default function CardTwo(props) {
  return (
    <>
      <img src={props.img} alt="service-images" className="w-90" />
      <h4 className="fw-600 pt-3">{props.title}</h4>
      <ButtonRound
        to={props.to}
        title=">"
        className="btn-round mx-auto green"
      />
    </>
  );
}
