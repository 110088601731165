import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import CTA from "../../components/CTA";
import CardThree from "../../components/CardThree";
import SideBar from "../../components/SideBar";
import CardFour from "../../components/CardFour";
import Skin1 from "../../assets/img/skin1.png";
import Aes1 from "../../assets/img/aes1.png";
import Hair1 from "../../assets/img/hair1.png";
import Hair2 from "../../assets/img/hair2.png";
import Hair3 from "../../assets/img/hair3.png";
import Hair4 from "../../assets/img/hair4.png";
import Ayur from "../../assets/img/s4.png";

export default function HairCare() {
  const CardComponent = [
    {
      img: Hair1,
      title: "GFC procedure for Hair growth",
      tagline: "Unleash Your Hair's Full Potential with GFC Treatment",
    },
    {
      img: Hair2,
      title: "Mesotherapy",
      tagline: "Revive Your Hair with Mesotherapy",
    },
    {
      img: Hair3,
      title: "Low level laser therapy",
      tagline: "Revive Your Hair Growth with Low-Level Laser Therapy (LLLT)",
    },
    {
      img: Hair4,
      title: "Laser Hair Reduction",
      tagline: "Rejuvenate Your Skin with Pico Laser Treatment",
    },
  ];
  const sideBar = [
    {
      url: "/services/skincare",
      title: "Skin Care",
      img: Skin1,
    },
    {
      url: "/services/aesthetic",
      title: "Aesthetic Care",
      img: Aes1,
    },
    {
      url: "/services/ayurveda",
      title: "Ayurveda",
      img: Ayur,
    },
  ];
  return (
    <>
      <BreadCrumb
        title="Aakruthi Healthcare's Hair Care Services"
        tagline="Empowering you to embrace your inner and outer radiance"
      />
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-9 pb-mob">
            <div className="row">
              {CardComponent.map((item) => (
                <div className="col-md-6" key={item.title}>
                  <CardThree
                    img={item.img}
                    title={item.title}
                    tagline={item.tagline}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3">
            <SideBar heading="Other Services">
              {sideBar.map((item) => (
                <div className="pb-3" key={item.title}>
                  <CardFour url={item.url} img={item.img} title={item.title} />
                </div>
              ))}
            </SideBar>
          </div>
        </div>
      </div>
      <CTA />
    </>
  );
}
