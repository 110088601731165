import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import CTA from "../../components/CTA";
import CardThree from "../../components/CardThree";
import SideBar from "../../components/SideBar";
import CardFour from "../../components/CardFour";
import Skin1 from "../../assets/img/skin1.png";
import Skin2 from "../../assets/img/skin2.png";
import Skin3 from "../../assets/img/skin3.png";
import Skin4 from "../../assets/img/skin4.png";
import Skin5 from "../../assets/img/skin5.png";
import Skin6 from "../../assets/img/skin6.png";
import Hair1 from "../../assets/img/hair1.png";
import Aes1 from "../../assets/img/aes1.png";
import Ayur from "../../assets/img/s4.png";

export default function SkinCare() {
  const CardComponent = [
    {
      img: Skin1,
      title: "ACNE",
      tagline: "Acne Treatment: More Than Just Skin Deep",
    },
    {
      img: Skin2,
      title: "ACNE Scars",
      tagline: "Addressing Acne Scars: Restoring Confidence",
    },
    {
      img: Skin3,
      title: "Skin Tags and DPNs",
      tagline: "Removing Skin Tags and DPNs: Regaining Smoothness",
    },
    {
      img: Skin4,
      title: "Pigmentation",
      tagline: "Rejuvenate Your Skin with Pico Laser Treatment",
    },
    {
      img: Skin5,
      title: "Dark Circles",
      tagline: "Unveiling the Mystery of Dark Circles",
    },
    {
      img: Skin6,
      title: "Anti Ageing",
      tagline: "Achieve a Smoother, Younger Look with Anti-Wrinkle Injections",
    },
  ];

  const sideBar = [
    {
      url: "/services/haircare",
      title: "Hair Care",
      img: Hair1,
    },
    {
      url: "/services/aesthetic",
      title: "Aesthetic",
      img: Aes1,
    },
    {
      url: "/services/ayurveda",
      title: "Ayurveda",
      img: Ayur,
    },
  ];
  return (
    <>
      <BreadCrumb
        title="Aakruthi Healthcare's Skin Care Services"
        tagline="Empowering you to embrace your inner and outer radiance"
      />
      <div className="container pt-5 pb-5">
        <div className="row h-100">
          <div className="col-md-9 pb-mob">
            <div className="row">
              {CardComponent.map((item) => (
                <div className="col-md-6" key={item.title}>
                  <CardThree
                    img={item.img}
                    title={item.title}
                    tagline={item.tagline}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3">
            <SideBar heading="Other Services">
              {sideBar.map((item) => (
                <div className="pb-3" key={item.title}>
                  <CardFour url={item.url} img={item.img} title={item.title} />
                </div>
              ))}
            </SideBar>
          </div>
        </div>
      </div>
      <CTA />
    </>
  );
}
