import React from "react";
import Logo from "../../assets/img/logo.svg";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export default function NavBar({ about, services, cta }) {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={Logo} alt="LOGO" width={"140px"} height={"50px"} />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-right"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  aria-current="page"
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="/about"
                >
                  {about}
                </NavLink>
              </li>
              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="/"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {services}
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/">
                      Skin Services
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      Hair Services
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      Aesthetic Services
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="/services"
                >
                  {services}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="https://aakruthihealthcare.com/blog/"
                  target="_blank"
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active nav-link" : "nav-link"
                  }
                  to="/contact"
                >
                  Contact
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/bookappointment"
                  className={({ isActive }) =>
                    isActive ? "active nav-link cta-btn" : "nav-link"
                  }
                >
                  {cta}
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* <img src={Hero} alt="" className="w-100" /> */}
    </>
  );
}

NavBar.propTypes = {
  about: PropTypes.string.isRequired,
  services: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
};
