import React from "react";

export default function CardOne(props) {
  return (
    <>
      <img src={props.img} alt="service-images" className="w-50" />
      <h4 className="fw-600 pt-3">{props.title}</h4>
      <p>{props.para}</p>
    </>
  );
}
