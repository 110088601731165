import React from "react";
import Button from "./Button";

export default function CTA() {
  return (
    <>
      <div className="cta-ba">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>
                  Experience the magic of <span>Ayurvedic cosmetology</span>.
                  Talk to us today and embark on a journey towards radiant and
                  timeless beauty.
                </h1>
                <Button
                  to="/bookappointment"
                  title="Book An Appointment"
                  className="btn primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
