import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import CTA from "../components/CTA";
import About1 from "../assets/img/about1.png";
import About2 from "../assets/img/about2.png";
import About3 from "../assets/img/about3.png";
import CTAGreen from "../components/CTAGreen";
import Title from "../components/Title";
import CardOne from "../components/CardOne";
import Drss from "../assets/img/drss.png"

export default function About() {
  const CardComponent = [
    {
      img: About1,
      title: "Aesthetic medicine",
      para: "Non-surgical procedures to enhance appearance and address concerns",
    },
    {
      img: About2,
      title: "Laser treatments",
      para: "Advanced laser technology for various skin conditions and hair removal",
    },
    {
      img: About3,
      title: "Cosmetic surgeries",
      para: "Surgical procedures to achieve desired aesthetic outcomes",
    },
  ];
  return (
    <>
      <BreadCrumb
        title="Dr. Sushmitha Shiva Kumar"
        tagline="BA, BAMS, PG Diploma in cosmetology, trichology, and aesthetics."
      />
      {/* About Section */}
      <div className="container pt-5 pb-5 general">
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="title">
              <p className="pb-1">
                <span>Dr. Sushmitha Shiva Kumar</span>
              </p>
              <p>
                <em>A Dedicated and Knowledgeable Dermatologist</em>
              </p>
            </div>
            <p>
              <b> Dr. Sushmitha Shiva Kumar </b>is a qualified and passionate
              dermatologist with a diverse educational background. She holds a
              Bachelor of Ayurvedic Medicine and Surgery (BAMS) degree, a
              postgraduate diploma in cosmetology, trichology, and athletics,
              and a Bachelor of Arts (BA) in Sanskrit. Additionally, she has
              obtained a certificate in skin and venereal diseases.
            </p>
          </div>
          <div className="col-md-4">
            <img src={Drss} alt="Doctor" className="w-100 drss-home" />
          </div>
        </div>
      </div>

      <CTA />

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-12 text-center text-green">
            <Title
              title="Dr. SSK's vision goes beyond traditional medicine"
              tagline="She established her practice to bridge the gap between medicine and aesthetics, offering patients innovative solutions in:"
            />
          </div>
        </div>
        <div className="row pt-3">
          {CardComponent.map((item) => (
            <div className="col-md-4 text-center text-green" key={item.title}>
              <CardOne img={item.img} title={item.title} para={item.para} />
            </div>
          ))}
        </div>
      </div>

      <CTAGreen />
    </>
  );
}
