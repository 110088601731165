import React from 'react'

function SideBar(props) {
  return (
    <>
      <div className="card sidebar-nav p-4 h-100">
        <h4 className="text-white pb-3">{props.heading}</h4>
        {props.children}
      </div>
    </>
  );
}

export default SideBar
