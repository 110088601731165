import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import CTA from "../../components/CTA";
import CardThree from "../../components/CardThree";
import SideBar from "../../components/SideBar";
import CardFour from "../../components/CardFour";
import Hair1 from "../../assets/img/hair1.png";
import Skin1 from "../../assets/img/skin1.png";
import Aes1 from "../../assets/img/aes1.png";
import Ayur1 from "../../assets/img/ayur1.png"
import Ayur2 from "../../assets/img/ayur2.png";
import Ayur3 from "../../assets/img/ayur3.png";
import Ayur4 from "../../assets/img/ayur4.png";
import Ayur5 from "../../assets/img/ayur5.png";

function Ayurveda() {
  const CardComponent = [
    {
      img: Ayur1,
      title: "Obesity Management",
      tagline: "Shed pounds naturally with Aakruthi’s holistic approach",
    },
    {
      img: Ayur2,
      title: "Eye Therapies",
      tagline: "Enhance your vision with ancient Ayurvedic wisdom",
    },
    {
      img: Ayur3,
      title: "Pain Management",
      tagline: "Relieve pain, restore balance with personalized Ayurvedic care",
    },
    {
      img: Ayur4,
      title: "Digestive Disorders",
      tagline: "Heal your gut with nature's time-tested remedies",
    },
    {
      img: Ayur5,
      title: "General Ayurveda Management",
      tagline:
        "Achieve optimal wellness through comprehensive Ayurvedic solutions",
    },
  ];
  const sideBar = [
    {
      url: "/services/skincare",
      title: "Skin Care",
      img: Skin1,
    },
    {
      url: "/services/haircare",
      title: "Hair Care",
      img: Hair1,
    },
    {
      url: "/services/aesthetic",
      title: "Aesthetic Care",
      img: Aes1,
    },
  ];
  return (
    <>
      <BreadCrumb
        title="Aakruthi Healthcare's Ayurveda Services"
        tagline="Empowering you to embrace your inner and outer radiance"
      />
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-9 pb-mob">
            <div className="row">
              {CardComponent.map((item) => (
                <div className="col-md-6" key={item.title}>
                  <CardThree
                    img={item.img}
                    title={item.title}
                    tagline={item.tagline}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3">
            <SideBar heading="Other Services">
              {sideBar.map((item) => (
                <div className="pb-3" key={item.title}>
                  <CardFour url={item.url} img={item.img} title={item.title} />
                </div>
              ))}
            </SideBar>
          </div>
        </div>
      </div>
      <CTA />
    </>
  );
}

export default Ayurveda;
