import "./App.css";
import "./assets/css/custom.scss";
import "./assets/css/responsive.scss";
import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import NavBar from "./components/common/NavBar";
import Footer from "./components/common/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import BookAnAppointment from "./pages/BookAnAppointment";
import SkinCare from "./pages/services/SkinCare";
import HairCare from "./pages/services/HairCare";
import Aesthetic from "./pages/services/Aesthetic";
import NotFound from "./components/NotFound";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Ayurveda from "./pages/services/Ayurveda";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <BrowserRouter>
      <div className="App" onUpdate={() => window.scrollTo(0, 0)}>
        <NavBar
          about="About Dr. SSK"
          services="Aakruthi's Services"
          cta="Book An Appointment"
        />
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/skincare" element={<SkinCare />} />
            <Route path="/services/haircare" element={<HairCare />} />
            <Route path="/services/aesthetic" element={<Aesthetic />} />
            <Route path="/services/ayurveda" element={<Ayurveda />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/termsconditions" element={<Terms />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/bookappointment"
              element={<BookAnAppointment />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
