import React from "react";

function Terms() {
  return (
    <>
          <div className="container pt-5 pb-5">
              <div className="row">
                  <h3>Terms and Conditions</h3>
                  <p>Updating details...</p>
              </div>
      </div>
    </>
  );
}

export default Terms;
