import React from 'react'
import Button from './Button';

export default function CTAGreen() {
  return (
    <>
      <div className="pt-5 pb-5 cta-green">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 text-center">
              <h5>
                Dr. SSK prioritises patient satisfaction. She aims to fulfil
                individual appearance-related needs, addressing complexes and
                boosting self-esteem through personalised and comprehensive
                care.
              </h5>
              <Button
                to="/services"
                title="Explore Aakruthi’s Services"
                className="btn secondary"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
