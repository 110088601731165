import React from 'react'

function NotFound() {
  return (
    <div className="container">
      <div
        style={{ height: "50vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div>
          <h1>404 - Not Found</h1>
          <p>Sorry, the page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound
