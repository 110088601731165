import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

export default function ButtonRound(props) {
  return (
    <>
      <Link to={props.to}>
        <div className={props.className}>
          <MdKeyboardArrowRight />
        </div>
      </Link>
    </>
  );
}
