import React from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import Leaf from "../assets/img/leaf.png";
import Approach1 from "../assets/img/ap1.png";
import Approach2 from "../assets/img/ap2.png";
import Approach3 from "../assets/img/ap3.png";
import Poster from "../assets/img/poster.png";
import CTA from "../components/CTA";
import Drss from "../assets/img/drss.png";
import G1 from "../assets/img/g1.jpg";
import G2 from "../assets/img/g2.jpg";

const Home = () => {
  const ApproachComponent = [
    {
      title: "Experience the Transformation",
      para: "At Aakruthi, we are passionate about helping you unlock the secrets of timeless beauty through the power of Ayurveda. Founded by Dr. SSK, a renowned and dedicated practitioner of Ayurveda, our clinic offers a range of natural beauty care solutions that help you embrace your natural beauty.",
      img: Approach1,
    },
    {
      title: "Holistic Approach to Beauty",
      para: "We believe that true beauty goes beyond just external appearances. It is an expression of inner well-being and balance. That's why at Aakruthi, we emphasise a holistic approach to beauty that takes into account your overall wellness. Our treatments are designed to nourish not just your outer self, but also your mind and spirit, resulting in long-lasting and radiant beauty.",
      img: Approach2,
    },
    {
      title: "Harnessing the Power of Ayurveda",
      para: "Ayurveda, the ancient Indian healing system, offers a treasure trove of remedies and rituals for beauty care. Driven by our commitment to preserving the purity and authenticity of Ayurveda, our experts carefully curate treatments that deeply penetrate and rejuvenate your skin, hair, and body. We blend traditional wisdom with modern techniques to deliver effective and natural results.",
      img: Approach3,
    },
  ];
  return (
    <>
      <div className="hero">
        <div className="hero-cont">
          <div className="container text-center">
            <p>
              Welcome to <span>Aakruthi Healthcare</span>
            </p>
            <h1>
              Discover the Power of <span>Ayurvedic Cosmetology</span>
            </h1>
            <p className="tag">Rejuvenate Your Natural Beauty with Ayurveda</p>
            <Button
              to="/bookappointment"
              title="Book An Appointment"
              className="btn primary"
            />
          </div>
        </div>
      </div>
      {/* About Section */}
      <div className="container pt-5 pb-5 general">
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="title">
              <p>
                About <span>Aakruthi Healthcare</span>
              </p>
              <p>
                <b>Where Ayurveda Meets Beauty</b>
              </p>
            </div>
            <p>
              <b>Aakruthi</b> Healthcare is a leading Ayurvedic cosmetology
              clinic located in Chitradurga, Karnataka. It was founded by
              renowned dermatologist and Ayurvedic practitioner, Dr. Sushmitha
              Shiva Kumar. Aakruthi offers a wide range of services focused on
              skin, hair, and aesthetics, utilizing the ancient wisdom of
              Ayurveda combined with modern cosmetology techniques. The clinic
              believes that true beauty lies in a holistic approach that
              nourishes both the inner and outer self.
            </p>
          </div>
          <div className="col-md-4">
            <img
              src={Drss}
              alt="Doctor Sushma"
              className="w-100 drss-home"
              width={"100%"}
              height={"100%"}
            />
          </div>
        </div>
      </div>
      {/*CTA Section*/}
      <div className="cta leaf">
        <img
          src={Leaf}
          alt="Leaf infographic"
          className="abs"
          width={"100%"}
          height={"auto"}
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <h1>
                Unleash your <br />
                inner beauty, naturally.
              </h1>
              <div className="d-flex gap-2">
                <Button
                  to="/about"
                  title="Know Dr. Sushmitha Shiva Kumar"
                  className="btn secondary"
                />
                <Button
                  to="/bookappointment"
                  title="Book an Appointment Today"
                  className="btn secondary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Approach Section */}
      <div className="container pt-5 pb-5">
        {ApproachComponent.map((item) => (
          <div className="items" key={item.img}>
            <div className="item">
              <h3>{item.title}</h3>
              <p>{item.para}</p>
            </div>
            <div className="item">
              <img
                src={item.img}
                alt="About aakruthi"
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
        ))}
      </div>

      {/* CTA Section */}
      <CTA />

      {/* Explore Services */}
      <div className="container pt-5">
        <div className="row justify-content-center text-center">
          <div className="col-md-7">
            <h3 className="text-green">
              At Aakruthi, we are not just about enhancing your appearance; we
              are about empowering you to embrace your inner and outer radiance.
            </h3>
            <Button
              to="/services"
              title="Explore Aakruthi’s Services"
              className="btn green"
            />
          </div>
        </div>
      </div>
      <img
        src={Poster}
        alt="Banner"
        className="w-100"
        width={"100%"}
        height={"100%"}
      />

      <div className="container pt-5">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center text-green">
              <b>Awards & Recognisions</b>
            </h3>
          </div>
          <div className="col-md-6">
            <Link
              to="https://photos.app.goo.gl/vyqUyM5BkXicmSgd8"
              target="_blank"
            >
              <img
                src={G1}
                alt="Awards"
                className="img-fluid"
                width={"100%"}
                height={"100%"}
              />
            </Link>
          </div>
          <div className="col-md-6">
            <Link
              to="https://photos.app.goo.gl/vyqUyM5BkXicmSgd8"
              target="_blank"
            >
              <img
                src={G2}
                alt="Recognition"
                className="img-fluid"
                width={"100%"}
                height={"100%"}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
