import React from 'react'
import { Link } from 'react-router-dom';

function CardFour(props) {
  return (
    <>
      <Link to={props.url}>
        <img src={props.img} alt="service-images" className="" />
        <h6>{ props.title}</h6>
      </Link>
    </>
  );
}

export default CardFour
