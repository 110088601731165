import React from "react";
import BreadCrumb from "../../components/BreadCrumb";
import CTA from "../../components/CTA";
import CardThree from "../../components/CardThree";
import SideBar from "../../components/SideBar";
import CardFour from "../../components/CardFour";
import Aes1 from "../../assets/img/aes1.png";
import Aes2 from "../../assets/img/aes2.png";
import Aes3 from "../../assets/img/aes3.png";
import Aes4 from "../../assets/img/aes4.png";
import Aes5 from "../../assets/img/aes5.png";
import Aes6 from "../../assets/img/aes6.png";
import Aes7 from "../../assets/img/aes7.png";
import Aes8 from "../../assets/img/aes8.png";
import Hair1 from "../../assets/img/hair1.png";
import Skin1 from "../../assets/img/skin1.png";
import Ayur from "../../assets/img/s4.png";

export default function Aesthetic() {
  const CardComponent = [
    {
      img: Aes1,
      title: "Medifacial",
      tagline: "Embrace Radiant Skin with Aakruthi's Rehydrating Medifacial",
    },
    {
      img: Aes2,
      title: "Anti Ageing",
      tagline:
        "Regain Your Youthful Look with Anti-Wrinkle Injections at Aakruthi",
    },
    {
      img: Aes3,
      title: "Mesoglow",
      tagline: "Achieve Radiant Skin with Mesoglow at Aakruthi",
    },
    {
      img: Aes4,
      title: "Carbon Laser Peel",
      tagline:
        "Achieve a Brighter, Flawless Complexion with Aakruthi's Carbon Laser Peel",
    },
    {
      img: Aes5,
      title: "Vampire Facial",
      tagline: "For skin Rejuvenation and Brightness ",
    },
    {
      img: Aes6,
      title: "Silk Facial",
      tagline: "For Dull, Dehydrated and Sensitive Skin",
    },
    {
      img: Aes7,
      title: "Glutathione Treatment",
      tagline: "For Boosting Skin Immunity",
    },
    {
      img: Aes8,
      title: "Korean Facial",
      tagline: "For Glassy Skin and Glow",
    },
  ];
  const sideBar = [
    {
      url: "/services/skincare",
      title: "Skin Care",
      img: Skin1,
    },
    {
      url: "/services/haircare",
      title: "Hair Care",
      img: Hair1,
    },
    {
      url: "/services/ayurveda",
      title: "Ayurveda",
      img: Ayur,
    },
  ];
  return (
    <>
      <BreadCrumb
        title="Aakruthi Healthcare's Aesthetic Services"
        tagline="Empowering you to embrace your inner and outer radiance"
      />
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-9 pb-mob">
            <div className="row">
              {CardComponent.map((item) => (
                <div className="col-md-6" key={item.title}>
                  <CardThree
                    img={item.img}
                    title={item.title}
                    tagline={item.tagline}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-3">
            <SideBar heading="Other Services">
              {sideBar.map((item) => (
                <div className="pb-3" key={item.title}>
                  <CardFour url={item.url} img={item.img} title={item.title} />
                </div>
              ))}
            </SideBar>
          </div>
        </div>
      </div>
      <CTA />
    </>
  );
}
