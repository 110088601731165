import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import CTA from "../components/CTA";
import CardTwo from "../components/CardTwo";
import S1 from "../assets/img/s1.png";
import S2 from "../assets/img/s2.png";
import S3 from "../assets/img/s3.png";
import S4 from "../assets/img/s4.png";
import Title from "../components/Title";

export default function Services() {
  const CardComponent = [
    {
      img: S1,
      title: "Aakruthi’s Skin Services",
      link: "/services/skincare",
    },
    {
      img: S2,
      title: "Aakruthi’s Hair Services",
      link: "/services/haircare",
    },
    {
      img: S3,
      title: "Aakruthi’s Aesthetics Services",
      link: "/services/aesthetic",
    },
    {
      img: S4,
      title: "Aakruthi's Ayurveda",
      link: "/services/ayurveda",
    },
  ];
  return (
    <>
      <BreadCrumb
        title="Aakruthi Healthcare's Services"
        tagline="Empowering you to embrace your inner and outer radiance"
      />

      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-12 text-center text-green">
            <Title
              title="At Aakruthi Healthcare, we are not just about enhancing your appearance;"
              tagline="We are about empowering you to embrace your inner and outer radiance"
            />
          </div>
        </div>
        <div className="row pt-3">
          {CardComponent.map((item) => (
            <div className="col-md-6 text-center text-green servicescard" key={item.title}>
              <CardTwo img={item.img} title={item.title} to={item.link} />
            </div>
          ))}
        </div>
      </div>
      <CTA />
    </>
  );
}
