import React from "react";

function PrivacyPolicy() {
  return (
    <>
      <div className="container pt-5 pb-5">
        <div className="row">
          <h3>Privacy Policy</h3>
          <p>Updating details...</p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
