import React from 'react'

export default function Title(props) {
  return (
    <>
      <h3 className="fw-600">
        {props.title}
      </h3>
      <p>
        {props.tagline}
      </p>
    </>
  );
}
