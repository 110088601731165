import React from 'react'

export default function CardThree(props) {
  return (
    <>
      <div className="d-flex align-items-center text-green pt-4">
        <img src={props.img} alt="service-images" />
        <div className="mx-3">
          <h5 className="fw-600">{props.title}</h5>
          <p className="m-0">{props.tagline}</p>
        </div>
      </div>
    </>
  );
}
