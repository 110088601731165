import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import { Link } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

export default function Contact() {
  return (
    <>
      <BreadCrumb
        title="Contact Aakruthi Healthcare"
        tagline="Empowering you to embrace your inner and outer radiance"
      />
      <div className="container pt-5 pb-5">
        <div className="row justify-content-center contact">
          <div className="col-md-6">
            {/* <h4 className="pb-4">Contact Us</h4> */}
            <div className="icons">
              <ul className="list-unstyled contact">
                <li>
                  <Link to="https://maps.app.goo.gl/cfqU3MSQotwUhUZC8">
                    <FaMapMarkerAlt />{" "}
                    <span>
                      1st Floor, 2nd Cross, Veer Savarkar Road, <br />
                      Near Vasavi Mahal, Chitradurga - 577501
                    </span>
                  </Link>
                </li>
                <li className="d-flex gap-3">
                  <Link to="tel:919900311141">
                    <MdCall /> <span>+91 99003 11141</span>
                  </Link>
                  <Link to="tel:919482476234">
                    <span>+91 94824 76234</span>
                  </Link>
                </li>
                <li>
                  <Link to="mailto:Dr.Sushmitha@aakruthihealthcare.com">
                    <FaEnvelope />{" "}
                    <span>Dr.Sushmitha@aakruthihealthcare.com</span>
                  </Link>
                </li>
              </ul>
            </div>
            <h4 className="pb-4">Reach</h4>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15469.884791687555!2d76.4005383!3d14.2257129!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba753d55b78453%3A0x13d83f2d7610b17f!2sAakruthi%20Healthcare!5e0!3m2!1sen!2suk!4v1715962766993!5m2!1sen!2suk"
              width="100%"
              height="700px"
              title="unique"
              style={{ borderRadius: "10px" }}
            />
          </div>
          <div className="col-md-6">
            {/* <h4 className="pb-4">
              Feel free to submit contact form for any query.
            </h4>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="name"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="ABC"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Mobile Number</label>
              <input
                type="number"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="+91 XXXX XXXX XX"
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Message
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button> */}

            {/* Google Form */}

            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfF3ZqRZvPPF5tR2yjJoR5s9rdOWMPgfnte0O9DGAnZjkoHCg/viewform?embedded=true"
              width="640"
              height="991"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="Aakruthi Healthcare Contact Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
}
